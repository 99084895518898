<template>
  <BaseListPage locale-section="pages.sources" route="source">
    <SourcesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "SourcesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    SourcesList: () => import("./SourcesList")
  }
};
</script>
